@import "./mixins.scss";

$gradientStart: #99ce86;
$gradientMiddle: #f0c343;
$gradientEnd: #ff5d73;
$header-with-banner: 115px;
$backgroundRed: #9f2424;

.with-banner .sidebar {
  @include breakpoint(lg) {
    height: calc(100vh - #{$header-with-banner});
  }
}
.sidebar {
  background: $backgroundRed;
  position: relative;
  z-index: 9;
  border-left: 1px solid $backgroundRed;
  box-shadow: 0px 4px 25px #00000040;
  height: 50px;
  border-bottom-right-radius: 35px;
  border-bottom-left-radius: 35px;

  @include breakpoint(lg) {
    background: #fff;
    height: calc(100vh - 60px);
    border-radius: 0;
  }

  .sidebar__filters-container {
    position: relative;
    top: 10px;
    overflow: auto;
    max-height: calc(100vh - 110px);
    @include custom-scroll-bar();
    @include breakpoint(lg) {
      top: 0;
    }
    label {
      color: #fff;
      @include breakpoint(lg) {
        color: #000;
      }
    }
  }

  .sidebar-toggle {
    z-index: 9;
    padding: 10px 30px;
    border: 1px solid $backgroundRed;
    border-bottom-right-radius: 35px;
    border-bottom-left-radius: 35px;
    &.grid-sidebar {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0px;
      &:not(.expandable) {
        display: none;
      }
    }

    &.gradient-btn,
    &.red-btn {
      color: #fff;
    }
  }

  .sidebar-content {
    position: relative;
    height: 100%;
  }

  .close-filter-sidebar {
    $arrow-size: 30px;
    position: relative;
    margin: 0px auto;
    width: $arrow-size;
    height: $arrow-size;
    margin-bottom: 45px;
    &:before {
      content: " ";
      height: 100%;
      width: 100%;
      display: block;
      background: url("../assets/arrow.svg") 50% 50% no-repeat;
      background-size: $arrow-size $arrow-size;
      transform: rotate(90deg);
    }
  }

  &.expandable {
    transition: right 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    right: -20rem;
    .sidebar-toggle {
      position: relative;
      top: 10px;
      left: -112px;
    }
  }

  &.expandable.expanded {
    right: 0rem;
  }
}

.heat-map-legend {
  margin-top: -3px;
  width: 150px;
  padding: 1px 5px;
  height: 13px;
  background: linear-gradient(to right, $gradientStart 0%, $gradientMiddle 50%, $gradientEnd 100%);
  span {
    font-size: 12px;
    line-height: 10px;
  }
}

.options-select-container {
  outline: none;
  overflow: hidden;
  &.showing-options {
    color: #000;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .select-input {
      .select-sign:before {
        color: #000;
        transform: rotate(180deg);
      }
    }
  }
  .select-input {
    border: none;
    .text-box {
      padding-left: 19px;
    }
    .select-sign {
      margin-right: 10px;
    }
    .select-sign:before {
      display: inline-block;
      color: $backgroundRed;
      position: relative;
      transition: transform 0.3s ease-in-out;
    }
  }
}

.select-options {
  background: #fff;
  border-left: 1px solid #2774ae;
  border-bottom: 1px solid #2774ae;
  border-right: 1px solid #2774ae;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.29);
  max-width: 200px;
  li:not(:last-child) {
    margin-bottom: 10px;
  }
  li:hover {
    color: #58bbd9;
  }
}

@media (max-width: 768px) {
  .sidebar {
    &.expanded {
      height: calc(100vh - 3rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sidebar {
    &.expanded {
      height: calc(100vh - 30rem);
      overflow-y: auto;
    }
  }
}

@media (max-width: 1024px) {
  .sidebar {
    top: 0;
    position: absolute;

    .sidebar-toggle:not(.grid-sidebar) {
      width: 100%;
      left: 0;
      top: 0;
      font-size: 20px;
      text-align: left;

      &:after {
        content: " ";
        display: inline-block;
        width: 20px;
        height: 15px;
        margin-left: 10px;
        background: url("../assets/arrow.svg") 50% 50% no-repeat;
        background-size: 20px 18px;
        transition: all 0.3s ease-in-out;
      }
      &.expanded {
        &:after {
          transform: rotate(90deg);
        }
      }
      &.collapsed {
        &:after {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
