@import "./constants.scss";

#fake-loader {
  position: relative;
  width: 100%;
  border: 1px solid #A02524;
  #fake-loader__progress-bar {
    padding: 20px;
    width: 0px;
    text-align: center;
    background:#A02524;
  }

  #fake-loader__progress-status {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 9px;
    color: #000;
  }
}
