.bg-gray-opacity30 {
  background: rgba(228, 228, 228, 0.3);
}

.about-page--partners {
  img,
  span {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    &.cihr {
      height: 4.5rem;
    }
    &.evidence-prime {
      height: 2.2rem;
    }
    &.epistemonikos {
      height: 3rem;
    }
    &.bigg {
      height: 6rem;
    }
    &.gesi {
      height: 10rem;
    }
    &.amsterdam-umc {
      height: 3.2rem;
    }
    &.pgimer {
      height: 5.2rem;
    }
    &.who {
      height: 2rem;
    }
    &.kumc {
      height: 2.75rem;
    }
    &.agree {
      height: 5.5rem;
    }
    &.mgc {
      height: 6.5rem;
    }
    &.arche {
      height: 2.5rem;
    }
    &.sick-kids {
      height: 2.5rem;
    }
    &.barcelona-sant-pau {
      height: 4.5rem;
    }
    &.cochrane-child-health {
      height: 3rem;
    }
    &.lanzhouUniversityLogo {
      height: 6rem;
    }
    &.ebase {
      height: 5.5rem;
    }
    &.cochrane-methods {
      height: 5.5rem;
    }
    &.cebtm {
      height: 5.5rem;
    }
    &.ningbo {
      height: 5.2rem;
    }
  }

  span {
    line-height: 3.75rem;
    font-size: 3.75rem;

    &.who {
      color: #103c81;
    }
  }
}
