.tags-list {
  margin: 0px;
  padding: 5px 30px;
  flex-wrap: wrap;
  .tags-list__tag {
    margin-top: 2px;
    margin-right: 10px;
    padding: 2px 3px;
    background: #5c7080;
    font-size: 14px;
    border-radius: 5px;
    color: #fff;
    button {
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      position: relative;
      font-size: 20px;
      line-height: 18px;
      color: #fff;
      content: "";
    }
  }
}
