@import "./constants.scss";

@media (min-width: 576px) {
  .header-container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .header-container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .header-container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .header-container {
    max-width: 1140px;
  }
}

.header-container {
  max-width: 1360px;
}

.header-0 {
  height: 0;
  visibility: hidden;
  @media (min-width: 992px) {
    height: 100%;
    visibility: visible;
  }
}

.dropdown {
  position: relative;
  height: 100%;

  cursor: pointer;

  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: #fff solid 4px;
    &--no-indication {
      border-bottom: 0;
      padding-bottom: 4px;
    }
  }

  & p {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 1200px) {
      padding-top: 4px;
    }
  }

  &__list {
    width: 100%;
    bottom: -13.85rem;
    left: 0;
    right: 0;
    background-color: #fff;
    color: #000;
    z-index: 200;
    position: absolute;

    @media (min-width: 992px) {
      bottom: -14.25rem;
    }

    &--short{
      z-index: 199;
      bottom: -7.2rem;
    }

    & li {
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      height: 0;
      border-bottom: 1px solid #efefef;

      & a {
        display: block;
        width: 100%;
        height: 100%;

        & span {
          display: block;
          padding: 18px 12px;
        }
      }
    }

    &--open {
      & li {
        height: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    margin: 0 0.5rem;
  }
}
